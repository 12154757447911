import useAppSizes from 'hooks/useAppSizes'
import dynamic from 'next/dynamic'
import LazyHydrate from 'react-lazy-hydration'
import ErrorBoundary from './ErrorBoundary/ErrorBoundary'
import withErrorBoundary from './ErrorBoundary/withErrorBoundary'
import getColorStyle from 'lib/getColorStyle'
import { useMedia } from 'use-media'
import { screens } from 'lib/constants'
import { useCountryCode } from 'context/globalStore'

const MODULES = {
  primaryHero: dynamic(() => import('modules/PrimaryHero')),
  primaryHeroV2: dynamic(() => import('modules/PrimaryHeroV2')),
  secondaryHero: dynamic(() => import('modules/SecondaryHero')),
  moment: dynamic(() => import('modules/Moment')),
  stickyScrollCarousel: dynamic(() => import('modules/StickyScrollCarousel')),
  iconGrid: dynamic(() => import('modules/IconGrid')),
  iconModule: dynamic(() => import('modules/IconModule')),
  categoryModule: dynamic(() => import('modules/CategoryModule')),
  featuredCollections: dynamic(() => import('modules/FeaturedCollections')),
  quickAddSubscriptionKit: dynamic(() => import('modules/QuickAddSubscriptionKit')),
  curatedReviewsCarousel: dynamic(() => import('modules/CuratedReviewsCarousel')),
  certificationModule: dynamic(() => import('modules/CertificationModule')),
  twoUp: dynamic(() => import('modules/twoUp/index')),
  press: dynamic(() => import('modules/Press')),
  marquee: dynamic(() => import('modules/Marquee')),
  imageCarousel: dynamic(() => import('modules/ImageCarousel')),
  productCarousel: dynamic(() => import('modules/ProductCarousel')),
  productHero: dynamic(() => import('modules/ProductHero')),
  advProductCarousel: dynamic(() => import('modules/AdvProductCarousel')),
  dividerLine: dynamic(() => import('modules/DividerLine')),
  callout: dynamic(() => import('modules/Callout')),
  cardCarousel: dynamic(() => import('modules/CardCarousel')),
  whatsInYourKit: dynamic(() => import('modules/WhatsInYourKit')),
  savingsCalculator: dynamic(() => import('modules/SavingsCalculator')),
  columns: dynamic(() => import('modules/Columns')),
  openLetter: dynamic(() => import('modules/OpenLetter')),
  statsScroller: dynamic(() => import('modules/StatsScroller')),
  threeUpScroller: dynamic(() => import('modules/ThreeUpScroller')),
  productMarquee: dynamic(() => import('modules/ProductMarquee')),
  basicCta: dynamic(() => import('modules/BasicCta')),
  advancedCta: dynamic(() => import('modules/AdvancedCta')),
  howItWorksScroller: dynamic(() => import('modules/HowItWorksScroller')),
  richText: dynamic(() => import('modules/RichText')),
  allReviews: dynamic(() => import('modules/OkendoReviews')),
  lpHero: dynamic(() => import('modules/lpHero')),
  ugcModule: dynamic(() => import('modules/ugcModule')),
  comparisonModule: dynamic(() => import('modules/ComparisonModule')),
  faqs: dynamic(() => import('modules/Faqs')),
  customForm: dynamic(() => import('modules/CustomForm')),
  videoHero: dynamic(() => import('modules/VideoHero')),
  subscriptionPLP: dynamic(() => import('modules/SubscriptionPLP')),
  reviewModuleV2: dynamic(() => import('modules/ReviewModuleV2')),
  reviewCarousel: dynamic(() => import('modules/OkendoCarousel')),
  ingredientModule: dynamic(() => import('modules/ingredientModule')),
  beforeAfterCarousel: dynamic(() => import('modules/BeforeAfterCarousel')),
  newModeEmbeddedForm: dynamic(() => import('modules/NewModeEmbeddedForm')),
  ugcVideo: dynamic(() => import('modules/ugcVideo/index')),
  howWeStackUp: dynamic(() => import('modules/howWeStackUp')),
  anchorPlacement: dynamic(() => import('modules/anchorNavPos')),
  customerLogin: dynamic(() => import('modules/CustomerLogin')),
  customerRecoverPassword: dynamic(() => import('modules/CustomerResetPassword')),
}

export default function GlobalModules({ modules, pageContext = {} }) {
  const isScreenLarge = useMedia({ minWidth: screens.l })
  const { countryCode } = useCountryCode()
  const { navHeight } = useAppSizes()

  if (!modules?.length) return null

  return modules.map((module, i) => {
    if (['scrollToSection', 'scrollToHash'].includes(module?._type)) return null

    const isFirst = i === 0
    const isLast = i === modules.length - 1
    const prevModule = isFirst ? null : modules[i - 1]
    const nextModule = isLast ? null : modules[i + 1]
    const prevModuleType = prevModule?._type
    const nextModuleType = nextModule?._type

    const settings = {
      hasMarginTop: prevModuleType !== 'moment',
      hasMarginBottom: nextModuleType !== 'moment',
      imageLoadPriority: i,
    }

    // Successive TwoUps should have minimal margins
    if (module?._type === 'twoUp') {
      const hasBgColor = !!getColorStyle(module.bgColor)
      if (prevModuleType === module?._type && !hasBgColor) {
        settings.hasMarginTop = false
      }

      if (nextModuleType === module?._type && !hasBgColor) {
        settings.hasMarginBottom = false
      }
    }

    const Module = MODULES[module?._type]
    const exClasses = module?._type === 'twoUp' && module?.bgColor ? ['relative', 'z-1'] : []
    let stickyClass: string[] = []
    if (module?._type === 'anchorPlacement') {
      if(countryCode !== "US"){
        stickyClass = ['sticky z-5 top-0']
      }else if(countryCode === "US"){ 
        stickyClass = ['sticky z-5 top-35']
      }
    }

    if (!Module) {
      return (
        <div
          className="text-navy-blue-muted text-opacity-50 pl-10 pt-10 last:pb-10"
          key={module._id}
        >
          missing - {module._type}
        </div>
      )
    }

    return (
      <div
        key={module._key}
        id={prevModule?.anchor?.reference?.hash || prevModule?.hash}
        className={['module', ...exClasses, ...stickyClass].join(' ')}
        style={{
          paddingTop: navHeight,
          marginTop: -navHeight,
        }}
      >
        <LazyHydrate whenVisible>
          <ErrorBoundary>
            <Module module={module} settings={settings} pageContext={pageContext} />
          </ErrorBoundary>
        </LazyHydrate>
      </div>
    )
  })
}
