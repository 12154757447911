import { qs, rect, on } from 'martha'
import { useState, useEffect } from 'react'

export default function useAppSizes() {
  const [windowSize, setWindowSize] = useState({
    windowWidth: 0,
    windowHeight: 0,
    navHeight: 0,
  })

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const desktopNavHeight = rect(qs('#desktop-nav'))?.height ?? 0
      const mobileNavHeight = rect(qs('#mobile-nav'))?.height ?? 0
      const anchorNavHeight = rect(qs('#anchor-nav'))?.height ?? 0
      const navHeight = desktopNavHeight + mobileNavHeight + anchorNavHeight

      setWindowSize({
        windowWidth: window.document.documentElement.clientWidth,
        windowHeight: window.document.documentElement.clientHeight,
        navHeight: typeof navHeight === 'number' ? navHeight : 0,
      })
    }
    // Add event listener
    let off = on(window, 'resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => off()
  }, []) // Empty array ensures that effect is only run on mount

  return windowSize
}
